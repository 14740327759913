define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/composer-fields/is-category-expert-question", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    <IsQuestionCheckbox @model={{this.model}} />
  {{/if}}
  */
  {
    "id": "bmtZTdUi",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"is-question-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/composer-fields/is-category-expert-question.hbs",
    "isStrictMode": false
  });
});