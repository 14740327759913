define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/composer-after-save-or-cancel/composer-is-question-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.site.mobileView}}
    <IsQuestionCheckbox @model={{this.model}} />
  {{/unless}}
  */
  {
    "id": "eOlM+GdP",
    "block": "[[[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"  \"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"is-question-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/composer-after-save-or-cancel/composer-is-question-checkbox.hbs",
    "isStrictMode": false
  });
});