define("discourse/plugins/discourse-category-experts/discourse/initializers/category-experts-post-decorator", ["exports", "@ember/runloop", "discourse/lib/plugin-api", "discourse-common/lib/deprecated", "discourse-common/lib/icon-library", "discourse/plugins/discourse-category-experts/discourse/components/category-experts-approve-button", "discourse/plugins/discourse-category-experts/discourse/components/category-experts-unapprove-button"], function (_exports, _runloop, _pluginApi, _deprecated, _iconLibrary, _categoryExpertsApproveButton, _categoryExpertsUnapproveButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    const requiresApproval = api.container.lookup("service:site-settings").category_experts_posts_require_approval;
    if (requiresApproval) {
      api.includePostAttributes("needs_category_expert_approval", "category_expert_approved_group", "can_manage_category_expert_posts");
      customizePostMenu(api);
    }
    api.decorateWidget("post:after", helper => {
      const post = helper.getModel();
      (0, _runloop.next)(() => {
        const article = document.querySelector(`article[data-post-id="${post.id}"]`);
        if (!article) {
          return;
        }
        if (post.category_expert_approved_group) {
          article.classList.add("category-expert-post");
          article.classList.add(`category-expert-${post.category_expert_approved_group}`);
        } else if (post.needs_category_expert_approval) {
          article.classList.remove("category-expert-post");
        }
      });
    });
    api.decorateWidget("poster-name:after", helper => {
      const post = helper.getModel();
      if (post && post.category_expert_approved_group) {
        return helper.h(`span.category-expert-indicator.category-expert-${post.category_expert_approved_group}`, (0, _iconLibrary.iconNode)("circle-check"));
      }
    });
  }
  function customizePostMenu(api) {
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref => {
      let {
        value: dag,
        context: {
          post,
          firstButtonKey,
          lastHiddenButtonKey,
          secondLastHiddenButtonKey
        }
      } = _ref;
      if (!post.can_manage_category_expert_posts) {
        return;
      }
      dag.add("category-expert-approve-post", _categoryExpertsApproveButton.default, {
        before: firstButtonKey
      });
      dag.add("category-expert-unapprove-post", _categoryExpertsUnapproveButton.default, {
        before: lastHiddenButtonKey,
        after: secondLastHiddenButtonKey
      });
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => customizeWidgetPostMenu(api));
  }
  function customizeWidgetPostMenu(api) {
    api.addPostMenuButton("category-expert-post-approval", attrs => {
      if (!attrs.can_manage_category_expert_posts) {
        return;
      }
      if (attrs.needs_category_expert_approval && !attrs.category_expert_approved_group) {
        return {
          action: "approveCategoryExpertPost",
          icon: "thumbs-up",
          className: "approve-category-expert-post",
          title: "category_experts.approve",
          label: "category_experts.approve",
          position: "first"
        };
      } else if (attrs.category_expert_approved_group && !attrs.needs_category_expert_approval) {
        return {
          action: "unapproveCategoryExpertPost",
          icon: "thumbs-down",
          className: "unapprove-category-expert-post",
          title: "category_experts.unapprove",
          position: "second-last-hidden"
        };
      }
    });
    const appEvents = api.container.lookup("service:app-events");
    api.attachWidgetAction("post", "approveCategoryExpertPost", function () {
      _categoryExpertsApproveButton.default.approveCategoryExpertPost(this.model, appEvents);
    });
    api.attachWidgetAction("post", "unapproveCategoryExpertPost", function () {
      _categoryExpertsUnapproveButton.default.unapproveCategoryExpertPost(this.model, appEvents);
    });
  }
  var _default = _exports.default = {
    name: "discourse-experts-post-decorator",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        initializeWithApi(api);
      });
    }
  };
});