define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/topic-category/is-question-indicator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.is_category_expert_question}}
    {{category-expert-question-indicator this.topic this.currentUser}}
  {{/if}}
  */
  {
    "id": "uEDRFjKH",
    "block": "[[[41,[30,0,[\"topic\",\"is_category_expert_question\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"topic\"]],[30,0,[\"currentUser\"]]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"category-expert-question-indicator\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/topic-category/is-question-indicator.hbs",
    "isStrictMode": false
  });
});