define("discourse/plugins/discourse-category-experts/discourse/components/category-experts-unapprove-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-category-experts/discourse/components/category-experts-approve-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _categoryExpertsApproveButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryExpertsUnapproveButton extends _component.default {
    static hidden = true;
    static shouldRender(args) {
      return args.post.category_expert_approved_group && !args.post.needs_category_expert_approval;
    }
    // TODO (glimmer-post-menu): Remove this static method and move the code into the button action after the widget code is removed
    static unapproveCategoryExpertPost(post, appEvents) {
      (0, _categoryExpertsApproveButton.setPostCategoryExpertAttributes)(post, appEvents, {
        approved: false
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    get showLabel() {
      return this.args.showLabel;
    }
    unapproveCategoryExpertPost() {
      CategoryExpertsUnapproveButton.unapproveCategoryExpertPost(this.args.post, this.appEvents);
    }
    static #_2 = (() => dt7948.n(this.prototype, "unapproveCategoryExpertPost", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="unapprove-category-expert-post"
          ...attributes
          @action={{this.unapproveCategoryExpertPost}}
          @icon="thumbs-down"
          @label={{if this.showLabel "category_experts.unapprove"}}
          @title="category_experts.unapprove"
        />
      
    */
    {
      "id": "EaU9flds",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"unapprove-category-expert-post\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"unapproveCategoryExpertPost\"]],\"thumbs-down\",[52,[30,0,[\"showLabel\"]],\"category_experts.unapprove\"],\"category_experts.unapprove\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-experts/discourse/components/category-experts-unapprove-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryExpertsUnapproveButton;
});