define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/user-card-additional-buttons/endorsement-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EndorsementButton
    @user={{this.user}}
    @close={{this.close}}
    @location="user-card"
  />
  */
  {
    "id": "66l4nIfy",
    "block": "[[[8,[39,0],null,[[\"@user\",\"@close\",\"@location\"],[[30,0,[\"user\"]],[30,0,[\"close\"]],\"user-card\"]],null]],[],false,[\"endorsement-button\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/user-card-additional-buttons/endorsement-button.hbs",
    "isStrictMode": false
  });
});