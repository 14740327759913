define("discourse/plugins/discourse-category-experts/discourse/pre-initializers/extend-for-category-experts", ["exports", "@ember/object/computed", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/plugins/discourse-category-experts/discourse/components/modal/expert-group-chooser"], function (_exports, _computed, _pluginApi, _composer, _expertGroupChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-category-experts",
    before: "inject-discourse-objects",
    initialize() {
      _composer.default.serializeOnCreate("is_category_expert_question", "is_category_expert_question");
      _composer.default.serializeOnUpdate("is_category_expert_question", "is_category_expert_question");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.modifyClass("model:category", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "allowingCategoryExpertEndorsements", [(0, _computed.and)("custom_fields.category_expert_group_ids", "custom_fields.category_accepting_endorsements")]))();
          #allowingCategoryExpertEndorsements = (() => (dt7948.i(this, "allowingCategoryExpertEndorsements"), void 0))();
          static #_2 = (() => dt7948.g(this.prototype, "allowingCategoryExpertQuestions", [(0, _computed.and)("custom_fields.category_expert_group_ids", "custom_fields.category_accepting_questions")]))();
          #allowingCategoryExpertQuestions = (() => (dt7948.i(this, "allowingCategoryExpertQuestions"), void 0))();
        });
        api.addPluginReviewableParam("ReviewableCategoryExpertSuggestion", "group_id");
        api.registerReviewableActionModal("approve_category_expert", _expertGroupChooser.default);
      });
    }
  };
});